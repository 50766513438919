// Base Colors
$color-logo: #733C30;  // Blue
$color-background: #D9C7B8; // Green
$color-bg-one: #8C7B65;   // Red
$color-light: #2c3e50; // Dark Gray
$color-bg-two: #A68160; // Light Gray
$color-text: #251D12;
$color-small-header: #590319;
$color-hover: #F2E2CE;
$color-mobile-header:#4e3c38;

body {
    background-color: #e9e9e9 !important;
}

.logo {
    a {
        text-decoration: none;
    }
.logo-wrapper {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    .logo-tech {
        background: $color-logo;
        color: #FFF;
        font-size: 18px;
        font-weight: bolder;
        padding: 5px 15px;
    }

    .logo-school {
        background: #ffffff;
        color: $color-logo;
        font-size: 18px;
        font-weight: bolder;
        padding: 5px 10px;
        text-transform: uppercase;
    }
}
}

#toggle-btn {
    display: none;
}

.main {
    min-height: 100vh;
}

.header-wrapper {
    background: #ffffff;
    padding: 15px;
    margin: 15px;

    .welcome-text {
        font-size: 18px;
        font-weight: 600;
        text-transform: capitalize;
    }

    .sub-welcome-text {
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
    }
}

.home-page-box {
    background: #fff;
    border: 1px solid #f5f5f5;
    margin: 10px;
    padding: 15px;

    .box-title {
        padding-left: 5px;
        margin-bottom: 10px;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: bold;
        color: $color-logo;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            padding: 5px;
            border-bottom: 1px solid #f5f5f5;
            font-size: 16px;
            font-weight: 500;
            text-transform: none;
            text-decoration: none;

            a {
                text-decoration: none;
                color: $color-bg-one;
            }

            a:hover {
                color: $color-small-header;
            }
        }
    }
}

.sidebar-wrapper {
    background-color: #f0f0f0;
    padding-left: 0px !important;
    padding-right: 0px !important;

    .logo {
        text-align: center;
        padding: 15px 0px;

        .logo-text a {
            display: none;
        }

        img {
            width: 230px;
            height: 40px;
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            &.link-header {
                background-color: $color-bg-two;
                font-weight: bold;
                padding: 5px 10px;
                border-radius: 0px;
                text-transform: uppercase;
                color: #ffffff;
                font-size: 13px;
                cursor: pointer;
                border-bottom: 2px solid #ccc;

                &:hover {
                    box-shadow: 0px 0px 5px #ccc;
                    color: #fff;
                    text-shadow: #bb06ee 0px 0px 2px;
                }

            }

            a {
                padding: 5px 15px;
                text-decoration: none;
                color: $color-logo;
                display: block;
                border-radius: 10px;
                font-size: 13px;
                font-weight: 500;
                text-transform: capitalize;
                margin: 2.5px 0px;
            }

            a:hover, a.active {
                background-color: $color-hover;
                border-radius: 5px;
            }
        }
    }
}

.main-content-wrapper {

    .main-header-title {

        font-weight: bold;
        padding: 15px;
        background-color: #fff;
        margin: 0px 0px;
        border-radius: 0px;
        text-align: center;
        font-size: 20px;
        color: $color-small-header;
    }

    .filter-questions {
        font-weight: bold;
        padding: 5px;
        background-color: #fff;
        margin: 10px 0px;
        border-radius: 3px;

        label {
            display: inline-block;
            padding: 5px 10px;
            font-size: 12px;
            cursor: pointer;
            font-weight: 500;

            input {
                margin-right: 5px;
                font-size: 16px;
            }
        }
    }

    .c-accordion {
        margin-top: 10px;
        details {
            background-color: #ffffff;
            border-radius: 5px;
        }

        summary {
            font-size: 14px;
            font-weight: 600;
            padding: 3px;
            background-color: #ffffff;
            cursor: pointer;
            color: $color-logo;
        }

        div > h4 {
            font-size: 13px;
        }

        .answer {
            h4, div > h4 {
                font-size: 13px;
            }
            ul {
                li {
                    font-size: 16px;
                    color: $color-text;
                }
            }
        }
    }

    .accordion-item {

        margin-bottom: 10px;
        border-radius: 10px;

        .accordion-button:not(.collapsed) {
            background-color: #fff;
            color: #000;
            font-size: 16px;
            font-weight: 600;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
    }

    .accordion-item:first-of-type {
        border-radius: 10px;

        .accordion-button {
            border-radius: 10px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;

        }

    }

    .accordion-item:not(:first-of-type) {
        border: 1px solid #f5f0f0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .accordion-button:focus {
        box-shadow: none;
        border-bottom: 1px solid #f5f0f0;
    }

    .accordion-content {
        padding: 15px;
    }

}

.links-wrapper {
    margin-top: 25px;

    .row {
        background-color: rgb(29 34 61) !important;
    }

    ul {
        padding: 50px 0px;
        list-style: none;
        margin: 0;

        li {

            a {
                color: #fff;
                text-decoration: none;
            }

            a:hover {
                color: #c2e7ff;
            }
        }
    }

    .footer {
        text-align: center;
        color: #fff;
        font-weight: 600;
        border-top: 1px solid #ddd;
        border-width: 80%;
        padding: 15px;
    }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {

    .main {
        display: block !important;
    }

    #toggle-btn {
        display: block;
        position: absolute;
        top: 17px;
        font-weight: bold;
        font-size: 18px;
    }

    .sidebar-wrapper {
        width: 100% !important;
        height: 60px;
        background: $color-mobile-header;
        color: #fff;

        .logo {
            .hide-in-mobile {
                display: none;
            }

            .logo-text a {
                font-size: 26px;
                font-weight: bold;
                display: block;
                position: absolute;
                text-align: center;
                width: 100%;
                top: 9px;
                text-decoration: none;
                color: #fff;
            }

            img {
                width: 200px;
                height: 35px;
            }
        }

        ul li.link-header {
            border-radius: 0px;
        }

        ul li a {
            padding: 5px 10px;
        }
    }

    #sidebar {
        height: 98vh;
        position: absolute;
        background: #fff;
        color: #000;
        z-index: 1;
        top: 60px;
        left: -500px;
        transition: left 0.5s ease-in-out;
        width: 62%;
        font-size: 13px;
        border-radius: 0px 5px 5px 0px;
    }

    #sidebar.open {
        left: 0;
    }

    .main-content {
        width: 100% !important;

        .main-content-wrapper {

            .main-header-title {
                font-size: 14px;
            }

            .filter-questions {
                label {
                    font-size: 12px;
                    padding: 5px;
                    ;
                }
            }
        }
    }

    .footer-top-section {
        width: 100% !important
    }

}